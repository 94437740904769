export default {
  data() {
    return {
      texts: "",
      bLoading: false,
      screenWidth: 0,
      sCategoryId: "",
      aCategory:[],
      sSubCategoryId: "",
      aSubCategory:[]
    };
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
    this.getCatalogo();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();

  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit("setDialogFilterArticle", false);
      // this.sCategoryId = "";
      // this.sSubCategoryId = "";
    },
    getCatalogo(){
      DB.get(`${URI}/api/v1/${this.selectLanguage}/categories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          // iPageNumber: this.iCurrentPage,
          // iItemsPerPage: this.iItemsPerPage,
          // sSearch: this.sSearch,
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          this.aCategory = response.data.results;          
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    getSubCatalogo(){      
      DB.get(`${URI}/api/v1/${this.selectLanguage}/subcategories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          sCategoryId: this.sCategoryId,
        },
      })
        .then((response) => {
          this.aSubCategory = response.data.results;      
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    filterArticles(){
      this.bLoading = true;
      this.$store.commit("setsCategoryId",this.sCategoryId);
      this.$store.commit("setsSubCategoryId",this.sSubCategoryId);
      this.$store.commit("refresher", true);
      this.bLoading = false;
      this.close();

      
    }
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogFilterArticle() {
      return this.$store.state.dialogFilterArticle;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
    },
    dialogFilterArticle: function () {
      if (this.dialogFilterArticle) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
    },
    
  },
};